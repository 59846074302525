import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout/Layout";
import Img from 'gatsby-image';
import './recipe.scss';

const Recipe = ({
                  location,
                  data, // this prop will be injected by the GraphQL query below.
                }) => {
  if (!data.markdownRemark) {
    return '';
  }
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const ingredientsImage = markdownRemark.frontmatter.ingredientsImage.childImageSharp.sizes;
  const { html } = markdownRemark;
  return (
    <Layout location={location} isBlogPost={true}>
      <div className="row justify-content-lg-center">
        <div className="col col-lg-8">
          <div className={'text-right'}>
            <Link to={'/recipes'}>Back to recipes list</Link>
          </div>
          <div className="blog-post-container">
            <h1>{markdownRemark.frontmatter.title}</h1>
            <div className="ingredients-image">
              <Img sizes={ingredientsImage}/>
            </div>
            <div className="blog-post text-justify">
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};


export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        category
        featureImage {
          childImageSharp{
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ingredientsImage {
          childImageSharp{
            sizes(maxWidth: 512, maxHeight:512 ) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`;

export default Recipe;
